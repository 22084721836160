<template>
  <!-- 学习记录 -->
  <div class="foxRight">
    <!-- 最近学习 -->
    <div v-if="list && list.length > 0" class="list">
      <div v-for="(item, index) in list" :key="index" class="item">
        <div class="content">
          <img class="item-img" :src="item.classesImg">
          <div class="item-title">
            <div class="item-text elp">{{ item.chapterName }}</div>
            <div class="item-tips ellipsis">{{ item.kName }}</div>
            <div class="data">{{ item.recordingTime }}看过</div>
          </div>
          <div class="keep-learns fles-shrink" @click="detail(item)">
            继续学习
          </div>
        </div>
      </div>
    </div>

    <!-- 暂无记录 -->
    <div v-if="!list || list.length == 0" class="nothing">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无记录哦~</div>
    </div>
    <el-pagination
      class="pagination"
      :total="total"
      :current-page="pageNum"
      :page-sizes="[8, 16, 24, 40]"
      :page-size="pageSize"
      :pager-count="5"
      background
      layout="total, sizes, prev, pager, next"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <el-dialog
      title=""
      custom-class="dpPlay"
      :visible.sync="ifDialog"
      :show-close="false"
      width="80%"
      append-to-body
    >
      <iframe
        :src="`${href}/pages/videoRoom?videoId=${id}&id=${
          dialogList ? dialogList.id : ''
        }&domainjwt=${domainjwt}&userId=${userId}&classId=${
          dialogList && dialogList.classesId ? dialogList.classesId : ''
        }`"
        width="100%"
        height="721px"
        frameborder="0"
        noResize
      />
    </el-dialog>
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : "" }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName : "" }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Know, courseCheckPlay } from '@/api/know'
const know = new Know()
import { getInfo, getToken, getDomainToken } from '@/api/cookies'
export default {
  data() {
    return {
      type: '',
      list: [],
      userId: null,
      total: 0,
      pageSize: 8,
      pageNum: 1,
      loading: null,
      ifDialog: false,
      href: process.env.VUE_APP_BASE_DpPlay,
      dialogList: null,
      domainjwt: getDomainToken(),
      lookDeatil: null,
      showLookDetail: false
    }
  },
  created() {
    this.userId = localStorage.getItem('userId')
    this.getList()
  },
  methods: {
    async clik(index) {
      this.total = 0
      this.pageNum = 1
      this.list = []
      this.pageSize = 8
      this.type = index
      await this.getList()
    },
    // 获取学习列表
    async getList() {
      this.loading = true
      await know
        .myCourseList(this.pageSize, this.pageNum, this.type)
        .then((res) => {
          for (const item of res.rows) {
            Vue.prototype.dayLong(item.recordingTime).then((data) => {
              item.recordingTime = data
            })
          }
          this.total = res.total
          this.list = res.rows
        })
      this.loading = false
    },
    /* 切换每页条数 */
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    closeLook() {
      this.showLookDetail = false
    },
    // 进入课堂
    detail(row) {
      switch (row.type) {
        case 1:
          const sub = {
            id: row.chapterId ? row.chapterId : undefined,
            classId: row.classesId ? row.classesId : undefined,
            startTime: row.startTime,
            chapterName: row.chapterName,
            classProductLine: row.productLine
          }
          const data = Vue.prototype.export(sub)
          courseCheckPlay({
            courseType: '1',
            userId: this.userId,
            chapterId: row.chapterId ? row.chapterId : undefined,
            source: 1
          }).then((res) => {
            if (res.code == 0) {
              this.$router.push(`/livebofang?${data}`)
            } else {
              this.lookDeatil = res.msg
              this.showLookDetail = true
            }
          })

          break
        case 2:
          if (row.isOutsourcing == 1) {
            this.ifDialog = true
            this.dialogList = item
          } else {
            const sub2 = {
              id: row.chapterId ? row.chapterId : undefined,
              uservideoConfig: row.recordsConfig
                ? row.recordsConfig
                : undefined,
              courseId: row.kId ? row.kId : undefined,
              classId: row.classesId ? row.classesId : undefined,
              coursePackageId: row.coursePackageId ? row.coursePackageId : undefined,
              typebs: 2,
              chapterName: row.chapterName ? row.chapterName : undefined,
              classProductLine: row.productLine
            }
            const data2 = Vue.prototype.export(sub2)
            courseCheckPlay({
              courseType: '2',
              userId: this.userId,
              chapterId: row.chapterId ? row.chapterId : undefined,
              source: 1
            }).then((res) => {
              if (res.code == 0) {
                this.$router.push(`/dianbofang?${data2}`)
              } else {
                this.lookDeatil = res.msg
                this.showLookDetail = true
              }
            })
          }

          break
        case 3:
          break
        case 4:
          this.$router.push(`/openclassxq?id=${row.classesId}`)
          break
        case 5:
          this.$router.push(
            `/ketangziliao?id=${row.kId}&classId=${row.classesId}`
          )
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="less" scoped>
.foxRight {
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  padding-bottom: 20px;
  .list {
    padding: 0 24px;
    .item {
      border: 0;
      .keep-learns {
        width: 80px;
        height: 28px;
        background: #ffffff;
        border-radius: 14px 14px 14px 14px;
        opacity: 1;
        border: 1px solid #1061ff;
        text-align: center;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #1061ff;
        line-height: 28px;
        // margin-top: 15px;
        cursor: pointer;
      }
    }
  }
  .foxRightTop {
    width: 100%;
    height: 130px;
    background: #ffffff;
    padding-bottom: 10px;
    .foxRightTopName {
      width: auto;
      height: auto;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #27323f;
      margin: 30px 0px 0px 30px;
    }
    .foxRightTopCen {
      height: 15px;
      margin: 30px 0px 0px 30px;
      // border: 1px solid #ff4027;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      .ps {
        width: auto;
        height: auto;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #7b8196;
        margin-right: 50px;
      }
      .pss {
        width: auto;
        height: auto;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff4027;
        margin-right: 50px;
      }
      span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #7b8196;
      }
      .spans {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff4027;
      }
      div {
        width: 1px;
        height: 14px;
        background: #a8abbe;
        margin: 0px 20px;
      }
    }
  }
  .foxRightCen {
    width: 100%;
    min-height: 580px;
    padding: 20px 0px 20px;
    background: #ffffff;
    overflow-y: scroll;
    scrollbar-width: none;
    margin-top: 20px;
    .el-pagination {
      width: auto;
      height: auto;
      margin: 50px auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .foxRightCenImg {
      width: 214px;
      height: 143px;
      margin: auto;
      margin-top: 180px;
      margin-bottom: 54px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    p {
      margin: auto;
      text-align: center;
      width: auto;
      height: auto;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #7b8196;
    }
    .history {
      width: 90%;
      height: 46px;
      margin: 30px auto;
      background: #fff8f4;
      border: 1px solid #f8af84;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      .historys {
        width: 97%;
        height: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .historysL {
          width: auto;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          img {
            width: 13px;
            height: 13px;
            margin-right: 10px;
          }
          span {
            width: auto;
            height: auto;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #f67e39;
          }
        }
        .historysR {
          width: 100px;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #f67e39;
          cursor: pointer;
        }
      }
    }
    .foxRightCens {
      width: 90%;
      height: 110px;
      margin: auto;
      margin-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      align-items: flex-start;
      .foxRightCensL {
        width: 163px;
        height: 90px;
        margin-right: 20px;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
      .foxRightCensR {
        width: 750px;
        height: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .foxRightCensRL {
          width: calc(750px - 150px);
          height: 100%;
          .foxRightCensRLTop {
            width: auto;
            margin-bottom: 15px;
            line-height: 18px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #27323f;
          }
          .foxRightCensRLCen {
            width: auto;
            p {
              width: auto;
              height: auto;
              float: left;
              font-size: 13px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #7b8196;
              line-height: 13px;
              span {
                color: #f15817;
              }
            }
            div {
              width: 1px;
              height: 12px;
              float: left;
              background: #a8abbe;
              margin: 3px 17px 0px 16px;
            }
          }

          .foxRightCensRLFot {
            width: auto;
            height: 18px;
            margin-top: 23px;
            display: flex;
            // 直播
            .foxRightCensRLFota {
              width: 40px;
              height: 18px;
              background: #ffffff;
              border: 1px solid #ff7439;
              border-radius: 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ff7439;
              margin-right: 10px;
              cursor: pointer;
            }
            // 录播
            .foxRightCensRLFotb {
              width: 40px;
              height: 18px;
              background: #ffffff;
              border: 1px solid #1b89fa;
              border-radius: 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #1b89fa;
              cursor: pointer;
            }
          }
        }
        .foxRightCensRR {
          flex-shrink: 0;
          width: 120px;
          height: 36px;
          border-radius: 18px;
          background: #ffffff;
          border: 1px solid #ff5400;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ff4027;
          cursor: pointer;
        }
      }
    }
  }
  //隐藏滚动条
  ::-webkit-scrollbar {
    width: 0 !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
  }
}
.list {
  width: 100%;
  padding: 32px;
  background-color: #fff;
  .item {
    width: 100%;
    padding: 32px 0px;
    border-bottom: 1px solid #f7f7f7;
    .content {
      display: flex;
      height: 108px;
      align-items: center;
      .item-img {
        width: 192px;
        height: 108px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        flex-shrink: 0;
        margin-right: 12px;
      }
      .item-title {
        width: 100%;
        height: 100%;
        position: relative;
        // display: grid;
        .item-text {
          font-size: 18px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #333333;
          line-height: 26px;
          width: 500px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .item-tips,
        .data {
          font-size: 14px;
          font-family: PingFang SC-Regular;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
        }
        .item-tips {
          margin-top: 12px;
        }
        .data {
          position: absolute;
          bottom: 0px;
        }
      }
    }
    .butBottom {
      margin-top: 32px;
      .data {
        font-size: 24px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #9fa4ad;
        line-height: 24px;
      }
    }
  }
}
/deep/ .el-pagination .active {
  background: #1061ff !important;
}
.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    .contents {
      width: 380px;
      margin: 20px auto;
      & > span {
        color: red;
      }
    }
    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>

<style lang="less"></style>
